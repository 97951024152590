// Icons
$asset-icon-shop: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1LgG');
$asset-icon-points: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1LgA');
$asset-icon-rewards: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1LgD');
$asset-icon-mission: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1LgM');
$asset-icon-faq: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1LgJ');
$asset-icon-info: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1LgP');
$asset-icon-edit: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1NU1');
$asset-icon-location: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1NTY');
$asset-icon-transactions: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1NTV');

// Sidebar Icons
$asset-icon-sidebar-points-balance: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1NU4');
$asset-icon-sidebar-points-history: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1NU7');
$asset-icon-sidebar-goodwill-mission: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1NTS');
$asset-icon-sidebar-store-locator: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1NTM');
$asset-icon-sidebar-contact-us: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1LgS');
$asset-icon-sidebar-account-settings: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/qgqnmzbzxm/binary/MA1LgV');
