.msc-pagination {
    display: inline-flex;
    justify-content: flex-end;
    overflow-x: auto;
    width: $width-container-pagination;

    > * {
        padding: $padding-text-pagination-page__mobile-small;

        @include media-breakpoint-up(xs) {
            padding: $padding-text-pagination-page__mobile;
        }

        @include media-breakpoint-up(sm) {
            padding: $padding-text-pagination-page;
        }
    }

    .msc-page-item {
        .msc-page-link {
            @extend %text-pagination-page;
            @include link-text-decoration(none);
        }

        &.active .msc-page-link {
            @extend %text-pagination-page-active;
        }

        &.disabled .msc-page-link {
            @extend %text-pagination-page-disabled;
        }
    }
}
