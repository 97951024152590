@mixin heading(
    $color: $color-heading,
    $font-family: $font-family-heading,
    $font-size: $font-size-heading,
    $font-size__mobile: $font-size-heading__mobile,
    $font-weight: $font-weight-heading,
    $font-style: $font-style-heading,
    $letter-spacing: $letter-spacing-heading,
    $line-height: $line-height-heading,
    $line-height__mobile: $line-height-heading__mobile,
    $text-transform: $text-transform-heading,
    $viewport: $breakpoint-default
) {
    color: $color;
    font-family: $font-family;
    font-size: $font-size__mobile;
    font-weight: $font-weight;
    font-style: $font-style;
    letter-spacing: $letter-spacing;
    line-height: $line-height__mobile;
    text-transform: $text-transform;

    @include media-breakpoint-up($viewport) {
        font-size: $font-size;
        line-height: $line-height;
    }
}

@mixin heading-color($color) {
    color: $color;
}

@mixin heading-font-size(
    $font-size,
    $font-size__mobile: $font-size,
    $viewport: $breakpoint-default
) {
    font-size: $font-size__mobile;

    @include media-breakpoint-up($viewport) {
        font-size: $font-size;
    }
}

@mixin heading-font-weight($font-weight) {
    font-weight: $font-weight;
}

@mixin heading-line-height(
    $line-height,
    $line-height__mobile: $line-height,
    $viewport: $breakpoint-default
) {
    line-height: $line-height__mobile;

    @include media-breakpoint-up($viewport) {
        font-size: $line-height;
    }
}

@mixin heading-letter-spacing($letter-spacing) {
    letter-spacing: $letter-spacing;
}

@mixin heading-text-transform($text-transform) {
    text-transform: $text-transform;
}
