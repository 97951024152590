// Scrollbar
$padding-scrollbar: 15px;
$length-scrollbar: 8px;
$side-scrollbar: 5px;
$border-radius-scrollbar: 10px;
$color-scrollbar-track: $color-background-accent;
$color-scrollbar-thumb: $color-border;
$color-scrollbar-thumb--hover: $color-border-muted;

%scrollbar {
    &::-webkit-scrollbar-track {
        border-radius: $border-radius-scrollbar;
        background-color: $color-scrollbar-track;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $border-radius-scrollbar;
        background-color: $color-scrollbar-thumb;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $color-scrollbar-thumb--hover;
    }
}

%scrollbar-y {
    @extend %scrollbar;
    overflow-y: auto;
    margin-right: -$padding-scrollbar;
    padding-right: $padding-scrollbar;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        width: $length-scrollbar;
    }

    &::-webkit-scrollbar-button {
        height: $side-scrollbar;
    }
}

%scrollbar-y-no-padding {
    @extend %scrollbar;
    overflow-y: auto;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        width: $length-scrollbar;
    }

    &::-webkit-scrollbar-button {
        height: $side-scrollbar;
    }
}

%scrollbar-x {
    @extend %scrollbar;
    overflow-x: auto;
    margin-bottom: -$padding-scrollbar;
    padding-bottom: $padding-scrollbar;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        height: $length-scrollbar;
    }

    &::-webkit-scrollbar-button {
        width: $side-scrollbar;
    }
}

%scrollbar-x-no-padding {
    @extend %scrollbar;
    overflow-x: auto;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        height: $length-scrollbar;
    }

    &::-webkit-scrollbar-button {
        width: $side-scrollbar;
    }
}
