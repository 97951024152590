//==============================================================================
// COMPONENT FONTS
//==============================================================================

// Text
$font-family-text: $font-family-worksans;

// Heading
$font-family-heading: $font-family-worksans;
$font-family-heading-h1: $font-family-heading;
$font-family-heading-h2: $font-family-heading;
$font-family-heading-h3: $font-family-heading;
$font-family-heading-h4: $font-family-heading;
$font-family-heading-h5: $font-family-heading;
$font-family-heading-h6: $font-family-heading;

// Button
$font-family-button: $font-family-worksans;
$font-family-button-primary: $font-family-button;
$font-family-button-secondary: $font-family-button-primary;
$font-family-button-accent: $font-family-button-primary;
$font-family-button-featured: $font-family-button-primary;

// Input
$font-family-input: $font-family-worksans;

// Label
$font-family-label: $font-family-worksans;

//==============================================================================
// FONT SIZES
//==============================================================================

// Text
$font-size-text: 15px;
$font-size-text__mobile: $font-size-text;

$font-size-text-large: 18px;
$font-size-text-large__mobile: $font-size-text-large;

$font-size-text-small: 14px;
$font-size-text-small__mobile: $font-size-text-small;

// Heading
$font-size-heading: 32px;
$font-size-heading__mobile: $font-size-heading;
$font-size-heading-h1: 48px;
$font-size-heading-h1__mobile: 42px;
$font-size-heading-h2: 42px;
$font-size-heading-h2__mobile: 32px;
$font-size-heading-h3: 36px;
$font-size-heading-h3__mobile: 26px;
$font-size-heading-h4: 30px;
$font-size-heading-h4__mobile: 22px;
$font-size-heading-h5: 24px;
$font-size-heading-h5__mobile: 20px;
$font-size-heading-h6: 20px;
$font-size-heading-h6__mobile: 18px;

// Button
$font-size-button: 16px;
$font-size-button__mobile: $font-size-button;

$font-size-button-primary: $font-size-button;
$font-size-button-primary__mobile: $font-size-button__mobile;

$font-size-button-secondary: $font-size-button-primary;
$font-size-button-secondary__mobile: $font-size-button-primary__mobile;

$font-size-button-accent: $font-size-button-primary;
$font-size-button-accent__mobile: $font-size-button-primary__mobile;

$font-size-button-featured: $font-size-button-primary;
$font-size-button-featured__mobile: $font-size-button-primary__mobile;

// Input
$font-size-input: $font-size-text;
$font-size-input__mobile: $font-size-input;

// Label
$font-size-label: 14px;
$font-size-label__mobile: $font-size-label;

// Icon
$font-size-icon: 16px;

//==============================================================================
// FONT WEIGHTS
//==============================================================================

$font-weight-black: 700;
$font-weight-bold: 600;
$font-weight-medium: 500;
$font-weight-normal: 400;

// Text
$font-weight-text: $font-weight-normal;

// Heading
$font-weight-heading: $font-weight-black;
$font-weight-heading-h1: $font-weight-heading;
$font-weight-heading-h2: $font-weight-heading;
$font-weight-heading-h3: $font-weight-heading;
$font-weight-heading-h4: $font-weight-heading;
$font-weight-heading-h5: $font-weight-heading;
$font-weight-heading-h6: $font-weight-heading;

// Button
$font-weight-button: $font-weight-bold;
$font-weight-button-primary: $font-weight-button;
$font-weight-button-secondary: $font-weight-button-primary;
$font-weight-button-accent: $font-weight-button-primary;
$font-weight-button-featured: $font-weight-button-primary;

// Input
$font-weight-input: $font-weight-normal;

// Label
$font-weight-label: $font-weight-normal;

//==============================================================================
// FONT STYLES
//==============================================================================

// Text
$font-style-text: normal;

// Heading
$font-style-heading: normal;
$font-style-heading-h1: $font-style-heading;
$font-style-heading-h2: $font-style-heading;
$font-style-heading-h3: $font-style-heading;
$font-style-heading-h4: $font-style-heading;
$font-style-heading-h5: $font-style-heading;
$font-style-heading-h6: $font-style-heading;

// Button
$font-style-button: normal;
$font-style-button-primary: $font-style-button;
$font-style-button-secondary: $font-style-button-primary;
$font-style-button-accent: $font-style-button-primary;
$font-style-button-featured: $font-style-button-primary;

// Input
$font-style-input: normal;

// Label
$font-style-label: normal;

//==============================================================================
// LETTER SPACING
//==============================================================================

// Text
$letter-spacing-text: 0;

// Heading
$letter-spacing-heading: 0;
$letter-spacing-heading-h1: $letter-spacing-heading;
$letter-spacing-heading-h2: $letter-spacing-heading;
$letter-spacing-heading-h3: $letter-spacing-heading;
$letter-spacing-heading-h4: $letter-spacing-heading;
$letter-spacing-heading-h5: $letter-spacing-heading;
$letter-spacing-heading-h6: $letter-spacing-heading;

// Button
$letter-spacing-button: 1px;
$letter-spacing-button-primary: $letter-spacing-button;
$letter-spacing-button-secondary: $letter-spacing-button-primary;
$letter-spacing-button-accent: $letter-spacing-button-primary;
$letter-spacing-button-featured: $letter-spacing-button-primary;

// Input
$letter-spacing-input: 0;

// Label
$letter-spacing-label: 0;

//==============================================================================
// LINE HEIGHTS
//==============================================================================

// Text
$line-height-text: 1.4;
$line-height-text__mobile: $line-height-text;

$line-height-text-large: $line-height-text;
$line-height-text-large__mobile: $line-height-text-large;

$line-height-text-small: $line-height-text;
$line-height-text-small__mobile: $line-height-text-small;

// Heading
$line-height-heading: 1.2;
$line-height-heading__mobile: $line-height-heading;

$line-height-heading-h1: $line-height-heading;
$line-height-heading-h1__mobile: $line-height-heading-h1;

$line-height-heading-h2: $line-height-heading;
$line-height-heading-h2__mobile: $line-height-heading-h2;

$line-height-heading-h3: $line-height-heading;
$line-height-heading-h3__mobile: $line-height-heading-h3;

$line-height-heading-h4: $line-height-heading;
$line-height-heading-h4__mobile: $line-height-heading-h4;

$line-height-heading-h5: $line-height-heading;
$line-height-heading-h5__mobile: $line-height-heading-h5;

$line-height-heading-h6: $line-height-heading;
$line-height-heading-h6__mobile: $line-height-heading-h6;

// Button
$line-height-button: 1.4;
$line-height-button__mobile: $line-height-button;

$line-height-button-primary: $line-height-button;
$line-height-button-primary__mobile: $line-height-button__mobile;

$line-height-button-secondary: $line-height-button-primary;
$line-height-button-secondary__mobile: $line-height-button-primary__mobile;

$line-height-button-accent: $line-height-button-primary;
$line-height-button-accent__mobile: $line-height-button-primary__mobile;

$line-height-button-featured: $line-height-button-primary;
$line-height-button-featured__mobile: $line-height-button-primary__mobile;

// Input
$line-height-input: 1.4;
$line-height-input__mobile: $line-height-input;

// Label
$line-height-label: 1.4;
$line-height-label__mobile: $line-height-label;

//==============================================================================
// TEXT TRANSFORM
//==============================================================================

// Text
$text-transform-text: none;

// Heading
$text-transform-heading: none;
$text-transform-heading-h1: $text-transform-heading;
$text-transform-heading-h2: $text-transform-heading;
$text-transform-heading-h3: $text-transform-heading;
$text-transform-heading-h4: $text-transform-heading;
$text-transform-heading-h5: $text-transform-heading;
$text-transform-heading-h6: $text-transform-heading;

// Button
$text-transform-button: none;
$text-transform-button-primary: $text-transform-button;
$text-transform-button-secondary: $text-transform-button-primary;
$text-transform-button-accent: $text-transform-button-primary;
$text-transform-button-featured: $text-transform-button-primary;

// Input
$text-transform-input: none;

// Label
$text-transform-label: none;

//==============================================================================
// TEXT DECORATION
//==============================================================================

// Link
$text-decoration-link: none;
$text-decoration-link--hover: none;

// Button
$text-decoration-button: none;
$text-decoration-button--hover: none;

$text-decoration-button-primary: $text-decoration-button;
$text-decoration-button-primary--hover: $text-decoration-button--hover;

$text-decoration-button-secondary: $text-decoration-button-primary;
$text-decoration-button-secondary--hover: $text-decoration-button-primary--hover;

$text-decoration-button-accent: $text-decoration-button-primary;
$text-decoration-button-accent--hover: $text-decoration-button-primary--hover;

$text-decoration-button-featured: $text-decoration-button-primary;
$text-decoration-button-featured--hover: $text-decoration-button-primary--hover;
