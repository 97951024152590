.ms-content-block {
    &.layout__hero {
        .ms-content-block__details {
            @extend %width-page;
            padding-top: 200px;
            padding-bottom: 75px;

            > * {
                max-width: 700px;
            }
        }
    }
}

.icon {
    &-shop .ms-content-block__title {
        @include icon-image($asset-icon-shop, before, 46px, 43px);
    }

    &-points .ms-content-block__title {
        @include icon-image($asset-icon-points, before, 48px, 52px);
    }

    &-rewards .ms-content-block__title {
        @include icon-image($asset-icon-rewards, before, 35px, 53px);
    }

    &-mission .ms-content-block__title {
        @include icon-image($asset-icon-mission, before, 45px, 43px);
    }

    &-faq .ms-content-block__title {
        @include icon-image($asset-icon-faq, before, 48px, 43px);
    }

    &-shop,
    &-points,
    &-rewards,
    &-mission,
    &-faq {
        .ms-content-block__title::before {
            margin: 0 auto 0.75rem;
        }
    }

    &-shop,
    &-points,
    &-rewards {
        .ms-content-block__title::before {
            height: 53px;
            background-position: center;
        }
    }
}

.landing,
.landing-content {
    > .container-with-header__slots {
        row-gap: unset;
        column-gap: unset;
    }
}

.update-account {
    > .ms-content-block__details {
        @include icon-image($asset-icon-info);

        &::before {
            margin: 0 auto 1rem;
        }
    }
}
