.ms-breadcrumb {
    @include media-breakpoint-down(sm) {
        display: none;
    }

    &_list {
        display: flex;
    }

    &_item {
        &:not(.breadcrumb_item-current) .ms-breadcrumb_link {
            @include plain-hover-all {
                text-decoration: underline;
            }
        }

        // Breadcrumb link separators have no class
        > span {
            margin-left: 0.4rem;
            margin-right: 0.6rem;
        }
    }
}
