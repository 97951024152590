.account {
    min-height: 100vh;
    background-color: $color-background-accent;

    > .container-with-header__slots {
        @extend %width-page;
        padding-top: 50px;
        padding-bottom: 50px;

        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    &-menu {
        @include media-breakpoint-down(md) {
            position: fixed;
            background-color: $color-background;
            z-index: 10;
            top: 0;
            left: 0;
            right: 0;
            padding: 1rem;
            border-bottom: 1px solid $color-border-muted;

            > .container-with-header__slots {
                flex-direction: row;
                align-items: center;

                > .logo {
                    flex: 1;

                    .ms-content-block {
                        display: block;
                    }

                    img {
                        max-height: 45px;
                    }
                }
            }

            .account-sidebar,
            .ms-header__signin-button,
            .ms-header__signout-button {
                display: none;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 200px;
        }
    }

    &-sidebar {
        .hidden-sidebar {
            display: none !important;
        }

        .account-sidebar-links {
            margin-top: 2rem;

            .list-of-links__link {
                @extend %link-secondary;
                display: flex;
                align-items: center;
                padding: 0.5rem 0.75rem;
                margin-top: 0;
                margin-right: 0;
                margin-bottom: 0.5rem;
                border-radius: 10px;
                line-height: 1;

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    background-color: $color-background-featured;
                }

                &.points-balance {
                    @include icon-image($asset-icon-sidebar-points-balance, before, 27px, 25px);
                }

                &.points-history {
                    @include icon-image($asset-icon-sidebar-points-history, before, 25px, 19px);
                }

                &.goodwill-mission {
                    @include icon-image($asset-icon-sidebar-goodwill-mission, before, 23px, 31px);
                }

                &.store-locator {
                    @include icon-image($asset-icon-sidebar-store-locator, before, 30px, 30px);
                }

                &.contact-us {
                    @include icon-image($asset-icon-sidebar-contact-us, before, 23px, 23px);
                }

                &.account-settings {
                    @include icon-image($asset-icon-sidebar-account-settings, before, 23px, 23px);
                }

                &::before {
                    width: 30px !important;
                    height: 31px !important;
                    margin-right: 0.5rem;
                    background-position: center;
                }
            }
        }

        .contact {
            margin-top: 2rem;

            .links-website .list-of-links__link {
                margin: 0;
            }
        }

        .legal {
            margin-top: 2rem;

            .list-of-links__link {
                @extend %link-secondary;
                display: block;
                margin-top: 0;
            }
        }
    }

    &-content {
        @extend %text-small;

        @include media-breakpoint-down(md) {
            margin-top: 50px;
        }

        @include media-breakpoint-up(lg) {
            flex: 1;
        }

        h1 {
            @include heading-font-size(40px, 34px);
        }

        h2 {
            @include heading-font-size(32px, 28px);
        }

        h3 {
            @include heading-font-size(28px, 24px);
        }

        h4 {
            @include heading-font-size(24px, 20px);
        }

        h5 {
            @include heading-font-size(20px, 18px);
        }

        h6 {
            @include heading-font-size(16px, 14px);
        }

        .container-with-header[class*='background'] {
            border-radius: 10px;
        }

        .ms-content-block__cta,
        .container-with-header__links {
            > * {
                padding: 0.5rem 1.5rem !important;
                font-size: 14px !important;
            }
        }

        > .container-with-header__slots {
            column-gap: 1rem;

            > .container-with-header { // Column
                @include media-breakpoint-up(lg) {
                    flex: 1;
                }

                > .container-with-header__slots > .container-with-header { // Block
                    padding: 1.5rem 2rem;
                    border: 1px solid $color-border-muted;
                    border-radius: 10px;
                    background-color: $color-background;

                    > .container-with-header__slots {
                        row-gap: 4rem;

                        .layout__columns > .container-with-header__slots {
                            flex-direction: column;
                            row-gap: 2rem;

                            .ms-content-block__title {
                                margin-bottom: 0.25rem;
                            }
                        }

                        .fill__section {
                            min-width: unset;
                            max-width: unset;
                            padding: 2rem;
                        }

                        .fill__container {
                            padding: 1rem 0;
                        }
                    }
                }
            }
        }

        &.layout__columns > .container-with-header__slots {
            @include media-breakpoint-up(md) {
                flex-wrap: wrap; // Need to wrap for when limiting max column count
            }

            > .container-with-header { // Column, specifically if layout is set to columns
                // Limit max column count to 2 as viewport width decreases
                @include media-breakpoint-up(md) {
                    $width-column: calc((100% - 1rem) / 2);
                    @include flex-width($width-column);
                }

                // Reset if largest viewport
                @include media-breakpoint-up(xxl) {
                    flex: 1;
                    max-width: unset;
                    min-width: unset;
                }
            }
        }

        &.columnwidths {
            $width-column: calc((((100% - 2rem) / 3) * 2) + 1rem);

            &__first > .container-with-header__slots {
                > *:first-child {
                    @include media-breakpoint-up(md) {
                        @include flex-width($width-column);
                    }
                }

                > *:not(:first-child) {
                    @include media-breakpoint-up(md) {
                        flex: 1;
                        max-width: unset;
                        min-width: unset;
                    }
                }
            }

            &__last > .container-with-header__slots {
                > *:last-child {
                    @include media-breakpoint-up(md) {
                        @include flex-width($width-column);
                    }
                }

                > *:not(:last-child) {
                    @include media-breakpoint-up(md) {
                        flex: 1;
                        max-width: unset;
                        min-width: unset;
                    }
                }
            }
        }
    }

    .hide-account {
        display: none;
    }
}
