//==============================================================================
// COMPONENT COLORS
//==============================================================================

// Border
$color-border: $color-gull;
$color-border-inverse: $color-abbey;
$color-border-accent: $color-alto;
$color-border-muted: $color-mystic;

// Background
$color-background: $color-white;
$color-background-inverse: $color-blumine;
$color-background-accent: $color-haze;
$color-background-accent-inverse: $color-madison;
$color-background-featured: $color-onahau;
$color-background-muted: $color-mystic;

// Text
$color-text: $color-abbey;
$color-text-inverse: $color-white;
$color-text-accent: $color-black;
$color-text-featured: $color-blumine;
$color-text-muted: $color-regent;
$color-text-error: $color-red;

// Heading
$color-heading: $color-black;
$color-heading-inverse: $color-white;
$color-heading-primary: $color-blumine;
$color-heading-secondary: $color-denim;
$color-heading-accent: $color-persian;
$color-heading-featured: $color-fire;
$color-heading-muted: $color-regent;

// Link
$color-link: $color-black;
$color-link--hover: $color-link;

$color-link-inverse: $color-white;
$color-link-inverse--hover: $color-link-inverse;

$color-link-primary: $color-blumine;
$color-link-primary--hover: $color-link-primary;

$color-link-secondary: $color-denim;
$color-link-secondary--hover: $color-link-secondary;

$color-link-accent: $color-persian;
$color-link-accent--hover: $color-link-accent;

$color-link-featured: $color-brandy;
$color-link-featured--hover: $color-link-featured;

$color-link-muted: $color-regent;
$color-link-muted--hover: $color-link-muted;

// Button
$color-button__border: $color-blumine;
$color-button__background: $color-blumine;
$color-button__text: $color-white;
$color-button__border--hover: $color-button__border;
$color-button__background--hover: $color-button__background;
$color-button__text--hover: $color-button__text;

$color-button-disabled__border: transparentize($color-button__border, 0.25);
$color-button-disabled__background: transparentize($color-button__border, 0.25);
$color-button-disabled__text: transparentize($color-button__text, 0.25);

$color-button-inverse__border: $color-white;
$color-button-inverse__background: transparent;
$color-button-inverse__text: $color-white;
$color-button-inverse__border--hover: $color-button-inverse__border;
$color-button-inverse__background--hover: $color-button-inverse__background;
$color-button-inverse__text--hover: $color-button-inverse__text;

$color-button-inverse-disabled__border: transparentize($color-button-inverse__border, 0.25);
$color-button-inverse-disabled__background: transparentize($color-button-inverse__border, 0.25);
$color-button-inverse-disabled__text: transparentize($color-button-inverse__text, 0.25);

$color-button-primary__border: $color-blumine;
$color-button-primary__background: $color-blumine;
$color-button-primary__text: $color-white;
$color-button-primary__border--hover: $color-button-primary__border;
$color-button-primary__background--hover: $color-button-primary__background;
$color-button-primary__text--hover: $color-button-primary__text;

$color-button-primary-disabled__border: transparentize($color-button-primary__border, 0.25);
$color-button-primary-disabled__background: transparentize($color-button-primary__background, 0.25);
$color-button-primary-disabled__text: transparentize($color-button-primary__text, 0.25);

$color-button-primary-inverse__border: $color-blumine;
$color-button-primary-inverse__background: $color-white;
$color-button-primary-inverse__text: $color-blumine;
$color-button-primary-inverse__border--hover: $color-button-primary-inverse__border;
$color-button-primary-inverse__background--hover: $color-button-primary-inverse__background;
$color-button-primary-inverse__text--hover: $color-button-primary-inverse__text;

$color-button-primary-inverse-disabled__border: transparentize($color-button-primary-inverse__border, 0.25);
$color-button-primary-inverse-disabled__background: transparentize($color-button-primary-inverse__background, 0.25);
$color-button-primary-inverse-disabled__text: transparentize($color-button-primary-inverse__text, 0.25);

$color-button-secondary__border: $color-shakespeare;
$color-button-secondary__background: $color-shakespeare;
$color-button-secondary__text: $color-white;
$color-button-secondary__border--hover: $color-button-secondary__border;
$color-button-secondary__background--hover: $color-button-secondary__background;
$color-button-secondary__text--hover: $color-button-secondary__text;

$color-button-secondary-disabled__border: transparentize($color-button-secondary__border, 0.25);
$color-button-secondary-disabled__background: transparentize($color-button-secondary__background, 0.25);
$color-button-secondary-disabled__text: transparentize($color-button-secondary__text, 0.25);

$color-button-secondary-inverse__border: $color-shakespeare;
$color-button-secondary-inverse__background: $color-white;
$color-button-secondary-inverse__text: $color-shakespeare;
$color-button-secondary-inverse__border--hover: $color-button-secondary-inverse__border;
$color-button-secondary-inverse__background--hover: $color-button-secondary-inverse__background;
$color-button-secondary-inverse__text--hover: $color-button-secondary-inverse__text;

$color-button-secondary-inverse-disabled__border: transparentize($color-button-secondary-inverse__border, 0.25);
$color-button-secondary-inverse-disabled__background: transparentize($color-button-secondary-inverse__background, 0.25);
$color-button-secondary-inverse-disabled__text: transparentize($color-button-secondary-inverse__text, 0.25);

$color-button-accent__border: $color-persian;
$color-button-accent__background: $color-persian;
$color-button-accent__text: $color-white;
$color-button-accent__border--hover: $color-button-accent__border;
$color-button-accent__background--hover: $color-button-accent__background;
$color-button-accent__text--hover: $color-button-accent__text;

$color-button-accent-disabled__border: transparentize($color-button-accent__border, 0.25);
$color-button-accent-disabled__background: transparentize($color-button-accent__background, 0.25);
$color-button-accent-disabled__text: transparentize($color-button-accent__text, 0.25);

$color-button-accent-inverse__border: $color-persian;
$color-button-accent-inverse__background: $color-white;
$color-button-accent-inverse__text: $color-persian;
$color-button-accent-inverse__border--hover: $color-button-accent-inverse__border;
$color-button-accent-inverse__background--hover: $color-button-accent-inverse__background;
$color-button-accent-inverse__text--hover: $color-button-accent-inverse__text;

$color-button-accent-inverse-disabled__border: transparentize($color-button-accent-inverse__border, 0.25);
$color-button-accent-inverse-disabled__background: transparentize($color-button-accent-inverse__background, 0.25);
$color-button-accent-inverse-disabled__text: transparentize($color-button-accent-inverse__text, 0.25);

$color-button-featured__border: $color-brandy;
$color-button-featured__background: $color-brandy;
$color-button-featured__text: $color-white;
$color-button-featured__border--hover: $color-button-featured__border;
$color-button-featured__background--hover: $color-button-featured__background;
$color-button-featured__text--hover: $color-button-featured__text;

$color-button-featured-disabled__border: transparentize($color-button-featured__border, 0.25);
$color-button-featured-disabled__background: transparentize($color-button-featured__background, 0.25);
$color-button-featured-disabled__text: transparentize($color-button-featured__text, 0.25);

$color-button-featured-inverse__border: $color-brandy;
$color-button-featured-inverse__background: $color-white;
$color-button-featured-inverse__text: $color-brandy;
$color-button-featured-inverse__border--hover: $color-button-featured-inverse__border;
$color-button-featured-inverse__background--hover: $color-button-featured-inverse__background;
$color-button-featured-inverse__text--hover: $color-button-featured-inverse__text;

$color-button-featured-inverse-disabled__border: transparentize($color-button-featured-inverse__border, 0.25);
$color-button-featured-inverse-disabled__background: transparentize($color-button-featured-inverse__background, 0.25);
$color-button-featured-inverse-disabled__text: transparentize($color-button-featured-inverse__text, 0.25);

// Input
$color-input__border: $color-gull;
$color-input__background: $color-haze;
$color-input__text: $color-abbey;
$color-input__border--active: $color-regent;
$color-input__background--active: $color-input__background;
$color-input__text--active: $color-input__text;
$color-input__border--error: $color-red;
$color-input__background--error: $color-input__background;
$color-input__text--error: $color-input__text;

// Label
$color-label: $color-regent;
