// Slider
$padding-container-slider: 0;

// Slider Line Track
$margin-top-line-slider-track: 0;
$color-background-line-slider-track: $color-input__text;

// Slider Line Background
$height-line-slider-background: 4px;
$color-background-line-slider-background: $color-input__border;

// Slide Thumb
$size-circle-slider-thumb: 12px;
$border-radius-circle-slider-thumb: 50%;
$outline-offset-circle-slider-thumb: 2px;
$background-circle-slider-thumb: $color-input__text;
$size-background-circle-slider-thumb: $size-circle-slider-thumb;

// Slider Thumb Before
$outline-offset-circle-slider-thumb-before: 0.0625rem;

// Slider Thumb Focus
$border-width-circle-slider-thumb-focus: 1px;
$border-style-circle-slider-thumb-focus: dashed;
$color-border-circle-slider-thumb-focus: $color-input__background;
$color-outline-circle-slider-thumb-focus: $color-input__text;

// Slider Item Labels
$padding-bottom-label-slider-item: 1.75rem;
$margin-top-label-slider-item: 2rem;
$font-size-label-slider-item: 14px;
