// Standard content width with padding and margins
%width-page {
    width: $width-page__mobile;
    min-width: $min-width-page;
    max-width: $max-width-page;
    padding: $padding-page__mobile;
    margin: $margin-page__mobile;

    @include media-breakpoint-up($breakpoint-default) {
        width: $width-page;
        padding: $padding-page;
        margin: $margin-page;
    }
}

// Standard content width with no padding
%width-page-without-padding {
    width: $width-page__mobile;
    min-width: $min-width-page;
    max-width: $max-width-page;
    margin: $margin-page__mobile;

    @include media-breakpoint-up($breakpoint-default) {
        width: $width-page;
        margin: $margin-page;
    }
}

// Standard content width with no padding above specified breakpoint
@mixin width-page-without-padding__breakpoint($breakpoint) {
    width: $width-page__mobile;
    min-width: $min-width-page;
    max-width: $max-width-page;
    padding: $padding-page__mobile;
    margin: $margin-page__mobile;

    @include media-breakpoint-up($breakpoint) {
        width: $width-page;
        padding-left: 0;
        padding-right: 0;
        margin: $margin-page;
    }
}

// Full-width content
%width-page-full {
    width: 100%;
    min-width: $min-width-page;
    max-width: none;
    padding: 0;
    margin: 0;
}

// Full-width for desktop, standard content width for mobile and tablet
%width-page-full__desktop-only {
    width: $width-page__mobile;
    min-width: $min-width-page;
    max-width: $max-width-page;
    padding: $padding-page__mobile;
    margin: $margin-page__mobile;

    @include media-breakpoint-up($breakpoint-default) {
        width: 100%;
        max-width: none;
        padding: 0;
        margin: 0;
    }
}

// Standard content width for desktop, full-width for mobile and tablet
%width-page-full__mobile-only {
    width: 100%;
    min-width: $min-width-page;
    max-width: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up($breakpoint-default) {
        width: $width-page;
        max-width: $max-width-page;
        padding: $padding-page;
        margin: $margin-page;
    }
}

// Content with specified width
@mixin width-container($width-content, $width-content__mobile: 100%) {
    width: $width-content__mobile;
    margin: auto;

    @include media-breakpoint-up($breakpoint-default) {
        width: $width-content;
    }
}

// Content with specified width with margin
@mixin width-container-with-margin(
    $width-content,
    $width-content__mobile: 100%,
    $ends: collapse
) {
    width: $width-content__mobile;
    margin: $margin-container-content__mobile;

    @include media-breakpoint-up($breakpoint-default) {
        width: $width-content;
        margin: $margin-container-content;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @if ($ends == collapse) {
        @extend %width-container-with-margin;
    }
}

%width-container-with-margin {
    &:first-child,
    .ms-breadcrumb + & {
        @include media-breakpoint-up($breakpoint-default) {
            margin-top: 0;
        }
    }
}
