.account-loyalty-spending {
    text-align: center;

    &__header {
        @include icon-image($asset-icon-mission, before, 45px, 43px);
        margin-bottom: 1rem;

        &::before {
            margin: 0 auto 0.75rem;
        }
    }

    &__spending-amount {
        font-weight: $font-weight-bold;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }

    &__items {
        margin-top: 2rem;
    }

    &__item {
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        &-value {
            @extend %heading-h5;
            @extend %heading-accent;
        }
    }

    &__footer {
        margin-top: 2rem;
    }

    &__button {
        @extend %button-accent;
        padding: 0.5rem 1.5rem;
        font-size: 14px;
    }
}
