@mixin link(
    $color: $color-link,
    $color--hover: $color-link--hover,
    $text-decoration: $text-decoration-link,
    $text-decoration--hover: $text-decoration-link--hover
) {
    @include transition;
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background-color: transparent;
    color: $color;
    text-decoration: $text-decoration;

    @include hover-all {
        outline: 0;
        background-color: transparent;
        color: $color--hover;
        text-decoration: $text-decoration--hover;
    }
}

@mixin link-color($color, $color--hover: $color) {
    color: $color;

    @include hover-all {
        color: $color--hover;
    }
}

@mixin link-text-decoration($text-decoration, $text-decoration--hover: $text-decoration) {
    text-decoration: $text-decoration;

    @include hover-all {
        text-decoration: $text-decoration--hover;
    }
}

@mixin link-remove($color: $color-text) {
    color: $color;
    text-decoration: none;
    pointer-events: none;

    @include hover-all {
        color: $color;
        text-decoration: none;
    }
}

@mixin link-disable {
    pointer-events: none;
}
