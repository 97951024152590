.account-lookup {
    &__header {
        margin-bottom: 2rem;
    }

    &__heading {
        margin-bottom: 1rem;

        &-title {
            @extend %heading-primary;
        }

        &-subtitle {
            @extend %heading-secondary;
        }
    }

    &__form-heading {
        margin-bottom: 1.5rem;
    }

    &__form {
        margin-bottom: 1.5rem;
    }

    &__label {
        @extend %label;

        &-link {
            @include link-text-decoration(underline);
            float: right;
            margin-top: 2px;
            font-size: 12px;
        }
    }

    &__input-group {
        margin-bottom: 1rem;
    }

    &__input {
        @extend %input;

        // Hide card field until replaced
        &-card {
            display: none;
        }
    }

    &__email-message a {
        @extend %link;
        @extend %link-secondary;
        @include link-text-decoration(underline);
    }

    &__buttons {
        @include center-flex(y);
        flex-direction: column;
        row-gap: 1rem;
        margin-top: 2rem;
    }

    &__button {
        @include button-width-full;

        &-primary {
            @extend %button-primary;

            &:disabled,
            &.disabled {
                @extend %button-primary-disabled;
            }
        }

        &-secondary {
            @extend %button-secondary;

            &:disabled,
            &.disabled {
                @extend %button-secondary-disabled;
            }
        }
    }

    &__link {
        @extend %link;
        @extend %link-primary;
        @include link-text-decoration(underline);
        @include text-font-weight($font-weight-bold);
    }

    &__alert {
        @extend %alert;

        &.error {
            @extend %alert-danger;
        }
    }

    // Override default button in all AAD pages
    .aad & .msc-btn {
        margin-top: 0;
    }
}
