.ms-address-form,
.msc-address-form {
    &__item {
        margin-bottom: 0.5rem;
    }

    &__label {
        @extend %label;
    }

    &__input {
        @extend %input;
        box-sizing: border-box !important; // Override inline styling from module
    }

    &__dropdown {
        @extend %select;
    }

    &__alert {
        @extend %alert;
        @extend %alert-danger;
        display: block;
        margin-top: 0.5rem;
    }

    &__button-save {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__button-cancel {
        @extend %button-secondary;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__button-save,
    &__button-cancel {
        margin-top: 1rem;
    }

    // @CUSTOM: Hide fields
    &__item {
        &-buildingcompliment,
        &-streetnumber,
        &-county,
        &-threeletterisoregionname,
        &-phone,
        &.pre-filled {
            display: none;
        }
    }
}
