.account-loyalty-summary {
    @include center-flex(y);
    justify-content: space-between;
    column-gap: 2rem;
    row-gap: 1rem;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }

    &__account {
        @include media-breakpoint-up(xl) {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            column-gap: 1.5rem;
        }

        &-user {
            @extend %text-large;
            font-weight: $font-weight-bold;
        }

        &-info {
            display: flex;

            > * {
                &:empty {
                    display: none;
                }

                &:not(:first-child)::before {
                    content: '/';
                    margin: 0 0.5rem;
                }
            }
        }

        &-tier-label {
            margin-right: 0.25rem;
        }
    }

    &__points {
        @include icon-content($icon-star);
        @include center-flex(y);
        column-gap: 0.5rem;
        padding: 0.25rem 0.75rem;
        border-radius: $border-radius-button;
        background-color: $color-heading-featured;
        color: $color-heading-inverse;
        font-size: 22px;
        font-weight: $font-weight-bold;

        &::before {
            font-size: 18px;
        }
    }

    .background__darkblue & {
        @extend %text-inverse;

        &__points {
            @extend %text-featured;
        }
    }
}
