.ms-store-select,
.ms-store-locate {
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        max-width: 400px;
    }

    &__body {
        margin-top: 1rem;
    }

    &__body,
    &__body_wrapper,
    &__store_container {
        @include media-breakpoint-up(md) {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: auto;
        }
    }

    &__found-locations {
        margin-bottom: 0.5rem;
    }

    &__search {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 0.5rem;

        + * {
            margin-top: 2rem;
            overflow: auto;
        }
    }

    &__search-form {
        @include flex-width(100%);
        display: flex;
        column-gap: 0.5rem;
    }

    &__search-input {
        @extend %input;
    }

    &__search-button {
        @include icon-content($icon-search);
        @extend %button;
        padding: 0.75rem 1rem;
        border-radius: $border-radius-input;
    }

    &__search-see-all-stores {
        @include link-text-decoration(underline);
    }

    &__toggle-view {
        @include button-remove;
        @extend %text;
        @extend %text-small;
        @include link-text-decoration(underline);

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__locations {
        @include media-breakpoint-down(sm) {
            display: none;

            &.show {
                display: block;
            }
        }
    }

    &__location {
        margin-bottom: 1.5rem;

        &-line-item {
            &-header {
                @include center-flex(y);
            }

            &-store-index {
                @extend %text-large;
                @extend %text-muted;
                width: 25px;
                font-weight: $font-weight-bold;

                &::after {
                    content: '.';
                }
            }

            &-store-name {
                @extend %text-large;
                @extend %text-featured;
                font-weight: $font-weight-bold;

                .selected & {
                    @extend %heading-featured;
                }
            }

            &-store-distance {
                margin-top: 0.25rem;
                margin-left: 0.5rem;
            }

            &-content {
                margin-left: 25px;
            }
        }

        &-line-header {
            @extend %heading-secondary;
            margin-top: 0.5rem;
            margin-bottom: 0;
            font-weight: $font-weight-bold;
        }
    }

    &__store-hours {
        &-details {
            display: flex;
        }

        &-day {
            @include flex-width(90px);
        }

        &-time {
            flex: 1;
        }
    }

    &__map__selected_store {
        &__modal {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &__header {
            @include center-flex(y);
            column-gap: 0.25rem;
    
            &_close_button {
                @include button-remove;
                @include icon-content($icon-close);
                display: flex;
                margin-right: 0.25rem;
            }
        }
    }
}
