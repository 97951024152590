// Accordion
$margin-bottom-container-accordion: 3rem;

// Accordion Text
%text-accordion {
    @extend %text;
    @extend %text-small;
}

// Accordion Heading
$margin-bottom-heading-accordion: 1rem;

// Accordion Toggle Container
%text-accordion-toggle {
    @extend %text;
    @extend %text-small;
    @extend %text-muted;
}

// Accordion Toggle Link
$padding-link-accordion-toggle: 0 0.5rem;

%link-accordion-toggle {
    @include button-remove;
    @extend %link;
    padding: $padding-link-accordion-toggle;
}

// Accordion Item
$padding-container-accordion-item: 1rem 0;

// Accordion Button
$font-weight-button-accordion: $font-weight-medium;

%button-accordion {
    @include button-remove;
    @extend %text;
    @extend %text-accent;
    font-weight: $font-weight-button-accordion;
}

// Accordion Content
$padding-container-accordion-content: 2rem 0 1rem;

// Accordion Divider
$border-width-divider-accordion-edge: 1px;
$border-width-divider-accordion: $border-width-divider-accordion-edge 0 0;
$color-border-accordion-divider: $color-border;

%divider-accordion {
    @include border($color-border-accordion-divider, $border-width-divider-accordion);
}

// Accordion Icon
$margin-left-icon-accordion: 0.5rem;
$color-icon-accordion: $color-text;
$size-icon-accordion-expand: 18px;
$width-icon-accordion-collapse: 18px;
$height-icon-accordion-collapse: 1px;
$icon-accordion-expand: $icon-plus;
$icon-accordion-collapse: $icon-minus;

%icon-accordion {
    &-expand {
        @include icon-content($icon-accordion-expand, after);
    }

    &-collapse {
        @include icon-content($icon-accordion-collapse, after);
    }
}
