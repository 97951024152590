.ms-address-detail,
.msc-address-detail {
    &__item {
        @extend %text-large;
    }

    &__item-newline,
    &__item-address-detail_Phone,
    &__item-county {
        display: block;
    }

    &__main-item-empty,
    &__item-phone-label {
        display: none;
    }

    &__item-phone {
        @extend %text-muted;
        @include icon-content($icon-phone);
        margin-right: 0.35rem;
        font-size: 14px;
    }

    &__item-addresstypevalue {
        @extend %text-featured;
        @include text-text-transform(uppercase);
        @include text-font-weight($font-weight-bold);
    }

    &__item-city {
        &::after {
            content: ',';
            margin-right: 0.25rem;
        }
    }

    // @CUSTOM: Hide fields
    &__item {
        &-name,
        &-buildingcompliment,
        &-streetnumber,
        &-county,
        &-threeletterisoregionname,
        &-address-detail_Phone {
            display: none;
        }
    }
}
