// Styling specific to SiteBuilder editor live view
editor-controller-component {
    // layout/account
    .account-content {
        > .container-with-header__slots > * > .container-with-header > .container-with-header__slots > * > .container-with-header {
            padding: 1.5rem 2rem;
            border: 1px solid $color-border-muted;
            border-radius: 10px;
            background-color: $color-background;
        }
    }

    // layout/aad
    .aad {
        &-image {
            display: block;
            position: unset;
            width: unset;
            height: 100%;
        }

        > .container-with-header__slots > *:first-child:not(:only-child) {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
                position: fixed;
                width: 50%;
                height: 100vh;
            }

            .aad-image {
                height: 100%;
            }
        }

        &-contents {
            width: unset;
            min-width: unset;
            margin: unset;
            padding: unset;
        }

        > .container-with-header__slots > .aad-contents,
        > .container-with-header__slots > *:last-child {
            min-width: 350px;
            margin: auto;
            padding: $padding-container-aad-contents;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }

        > .container-with-header__slots > *:last-child:not(:only-child) {
            @include media-breakpoint-up(md) {
                margin-right: unset;
            }

            > * > .container-with-header__slots {
                @include media-breakpoint-up(md) {
                    margin-left: unset;
                }
            }
        }
    }
}
