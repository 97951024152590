//==============================================================================
// BASE COLORS
//==============================================================================

// All projects
$color-white: #fff;
$color-silver: #ccc;
$color-black: #000;
$color-red: #b90000;

// Project-specific
$color-haze: #f4f6f9;
$color-alto: #d8d8d8;
$color-mystic: #d9e0ea;
$color-gull: #a0abbc;
$color-regent: #8a94a4;
$color-abbey: #51585a;

$color-onahau: #d0edff;
$color-shakespeare: #3a95cd;
$color-denim: #1b75bc;
$color-blumine: #194790;
$color-madison: #0b2c60;
$color-persian: #04a39f;
$color-falcon: #8e5183;
$color-fire: #e89831;
$color-brandy: #c1782b;
