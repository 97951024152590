@mixin button(
    $padding: $padding-button,
    $border-width: $border-width-button,
    $border-style: $border-style-button,
    $border-radius: $border-radius-button,

    $color__border: $color-button__border,
    $color__background: $color-button__background,
    $color__text: $color-button__text,
    $color__border--hover: $color-button__border--hover,
    $color__background--hover: $color-button__background--hover,
    $color__text--hover: $color-button__text--hover,

    $font-family: $font-family-button,
    $font-size: $font-size-button,
    $font-size__mobile: $font-size-button__mobile,
    $font-weight: $font-weight-button,
    $font-style: $font-style-button,
    $letter-spacing: $letter-spacing-button,
    $line-height: $line-height-button,
    $line-height__mobile: $line-height-button__mobile,
    $text-transform: $text-transform-button,
    $text-decoration: $text-decoration-button,
    $text-decoration--hover: $text-decoration-button--hover,

    $viewport: $breakpoint-default
) {
    @include transition;
    display: inline-block;
    padding: $padding;
    outline: 0;
    border-radius: $border-radius;
    border-width: $border-width;
    border-style: $border-style;
    border-color: $color__border;
    background-color: $color__background;
    color: $color__text;
    font-family: $font-family;
    font-size: $font-size__mobile;
    font-weight: $font-weight;
    font-style: $font-style;
    letter-spacing: $letter-spacing;
    line-height: $line-height__mobile;
    text-transform: $text-transform;
    text-decoration: $text-decoration;
    text-align: center;
    cursor: pointer;

    @include media-breakpoint-up($viewport) {
        font-size: $font-size;
        line-height: $line-height;
    }

    @include hover-all {
        outline: 0;
        border-color: $color__border--hover;
        background-color: $color__background--hover;
        color: $color__text--hover;
        text-decoration: $text-decoration--hover;
    }
}

@mixin button-color(
    $color__border,
    $color__background,
    $color__text,
    $color__border--hover: $color__border,
    $color__background--hover: $color__background,
    $color__text--hover: $color__text
) {
    border-color: $color__border;
    background-color: $color__background;
    color: $color__text;

    @include hover-all {
        border-color: $color__border--hover;
        background-color: $color__background--hover;
        color: $color__text--hover;
    }
}

@mixin button-color-text(
    $color__text,
    $color__text--hover: $color__text
) {
    color: $color__text;

    @include hover-all {
        color: $color__text--hover;
    }
}

@mixin button-padding($padding) {
    padding: $padding;
}

@mixin button-border(
    $color__border,
    $color__border--hover: $color__border,
    $border-width: 1px,
    $border-style: solid,
) {
    border-width: $border-width;
    border-style: $border-style;
    border-color: $color__border;

    @include hover-all {
        border-color: $color__border--hover;
    }
}

@mixin button-size(
    $padding,
    $border-width,
    $font-size,
    $font-size__mobile: $font-size,
    $viewport: $breakpoint-default
) {
    padding: $padding;
    border-width: $border-width;
    font-size: $font-size__mobile;

    @include media-breakpoint-up($viewport) {
        font-size: $font-size;
    }
}

@mixin button-width($width) {
    width: $width;
}

@mixin button-width-auto {
    width: auto;
}

@mixin button-width-full {
    width: 100%;
}

@mixin button-width-full__mobile-only {
    width: 100%;

    @include media-breakpoint-up(md) {
        width: auto;
    }
}

@mixin button-min-width($min-width) {
    min-width: $min-width;
}

@mixin button-font-size(
    $font-size,
    $font-size__mobile: $font-size,
    $viewport: $breakpoint-default
) {
    font-size: $font-size__mobile;

    @include media-breakpoint-up($viewport) {
        font-size: $font-size;
    }
}

@mixin button-letter-spacing($letter-spacing) {
    letter-spacing: $letter-spacing;
}

@mixin button-line-height(
    $line-height,
    $line-height__mobile: $line-height,
    $viewport: $breakpoint-default
) {
    line-height: $line-height__mobile;

    @include media-breakpoint-up($viewport) {
        line-height: $line-height;
    }
}

@mixin button-text-transform($text-transform) {
    text-transform: $text-transform;
}

@mixin button-underline(
    $text-decoration-thickness,
    $text-underline-offset,
    $text-decoration-color
) {
    text-decoration: underline;
    text-decoration-line: underline;
    text-decoration-thickness: $text-decoration-thickness;
    text-decoration-color: $text-decoration-color;
    text-underline-offset: $text-underline-offset;
}

@mixin button-remove {
    padding: 0;
    border: 0;

    &,
    &:focus,
    &:active {
        outline: 0;
        background-color: transparent;
    }
}

@mixin button-disable {
    pointer-events: none;
}
