.simple-form {
    &__header {
        margin-bottom: 2rem;
    }

    &__input-group {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        &.required label::after {
            content: '*';
            color: $color-text-error;
        }
    }

    &__label {
        @extend %label;
    }

    &__input {
        @extend %input;
    }

    &__select {
        @extend %select;
    }

    &__textarea {
        @extend %textarea;
    }

    &__button {
        @extend %button;
        @include button-width-full;
        margin-top: 1.5rem;

        &:disabled,
        &.disabled {
            @extend %button-disabled;
        }
    }

    &__messages {
        @extend %alert;
        margin-top: 1rem;

        &.error {
            @extend %alert-danger;
        }

        &.success {
            @extend %alert-success;
        }

        span {
            display: block;
        }
    }

    &.contact {
        max-width: 345px;
        width: 100%;
        margin: auto;

        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }

        .simple-form__title {
            @include media-breakpoint-up(md) {
                text-align: center;
            }
        }

        .contact__required::before {
            content: '*';
            color: $color-text-error;
        }
    }
}
