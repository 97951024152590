.list-of-links {
    &__link {
        display: inline-block;
        margin-right: 1rem;
        margin-top: 0.9rem;
        margin-bottom: 0.9rem;
        line-height: 1.2;

        &:last-child {
            margin-right: 0;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-bottom: 0;
            line-height: 2;
        }
    }

    &.links-social {
        .list-of-links__link {
            @extend %link-primary;
            font-size: 0;

            &::before {
                font-size: $font-size-icon;
            }

            &.facebook {
                @include icon-content($icon-facebook, $style: brands);
            }

            &.instagram {
                @include icon-content($icon-instagram, $style: brands);
            }

            &.twitter {
                @include icon-content($icon-twitter, $style: brands);
            }

            &.youtube {
                @include icon-content($icon-youtube, $style: brands);
            }

            &.pinterest {
                @include icon-content($icon-pinterest, $style: brands);
            }

            &.linkedin {
                @include icon-content($icon-linkedin, $style: brands);
            }
        }
    }

    &.links-website {
        .list-of-links__link {
            @extend %link-primary;
            @include link-text-decoration(underline);
            font-weight: $font-weight-bold;
        }
    }
}
