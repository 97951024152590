%text {
    @include text;

    &-large {
        @include text-font-size(
            $font-size-text-large,
            $font-size-text-large__mobile
        );
    }

    &-small {
        @include text-font-size(
            $font-size-text-small,
            $font-size-text-small__mobile
        );
    }

    &-inverse {
        @include text-color($color-text-inverse);
    }

    &-accent {
        @include text-color($color-text-accent);
    }

    &-featured {
        @include text-color($color-text-featured);
    }

    &-muted {
        @include text-color($color-text-muted);
    }
}
