header:not([class]),
.landing-header {
    width: 100%;

    .ms-header,
    .logo {
        @extend %width-page;
    }

    .logo {
        margin-top: 50px;
    }
} 

.landing-header {
    position: absolute;
    z-index: 100;
}

.ms-header__nav-icon {
    @include button-remove;
    @include icon-content($icon-nav);
    padding: 0.5rem 0.6rem;
    border-radius: 5px;
    font-size: 24px;
    line-height: 24px;

    @include plain-hover-all {
        background-color: $color-background-inverse;
        color: $color-link-inverse;
    }
}

// Hide on Desktop
.ms-header__nav-icon,
.ms-header__modal {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.logo {
    .container-with-header__slots {
        display: block;
    }

    .ms-content-block {
        display: inline-block;
    }
}

// Mobile Header
.ms-header {
    &__modal {
        position: fixed;
        overflow-y: auto;
        z-index: 120;
        top: 0;
        right: 0;
        width: 300px;
        height: 100%;
        background-color: $color-background-accent;
        border-left: 1px solid $color-border-muted;

        .msc-modal__content {
            padding: 1.5rem;
        }

        .msc-modal__close-button {
            @include button-remove;
            @include icon-content($icon-close);
            position: absolute;
            top: 20px;
            right: 25px;
            color: $color-link-primary;
        }

        .ms-header__mobile-hamburger-menu-links {
            margin-top: 1rem;
        }
    }
}

.ms-header__signin-button,
.ms-header__signout-button {
    @extend %text-small;
    @extend %link;
    @extend %link-featured;
    @include link-text-decoration(underline);
}
