.msc-notifications-list {
    .msc-notification-error {
        @extend %alert;
        @extend %alert-danger;
        padding: 0.5rem 0.75rem;
    }

    .msc-notification-title {
        overflow-wrap: break-word;
        font-weight: $font-weight-bold;
        line-height: 1.2;
    }

    .msc-notification-close {
        display: none;
    }
}
