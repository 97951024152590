.account-loyalty-transactions {
    &__heading {
        @include icon-image($asset-icon-transactions, $width: 18px, $height: 24px);
        @include center-flex(y);
        column-gap: 0.5rem;
    }

    &__transactions {
        margin-top: 1rem;
    }

    &__transaction {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;
        border-bottom: 1px solid $color-border-muted;

        &:first-child {
            border-top: 1px solid $color-border-muted;
        }

        &-info,
        &-cost {
            display: flex;
            flex-direction: column;

            > * {
                flex: 1;
            }
        }

        &-cost {
            align-items: flex-end;
        }

        &-channel,
        &-points {
            @extend %text;
            @extend %text-accent;
        }

        &-points {
            @include center-flex(y);
            @include icon-content($icon-star);
            font-weight: $font-weight-bold;

            &::before {
                margin-right: 0.25rem;
                color: $color-heading-featured;
                font-size: 12px;
            }

            &.negative::before {
                color: $color-heading-accent;
            }
        }

        &-date,
        &-operation {
            @extend %text-muted;
        }
    }

    &__footer {
        margin-top: 1rem;
    }

    &__see-more {
        @extend %link-primary;
        @include link-text-decoration(underline);
    }

    &.summary .account-loyalty-transactions {
        &__transaction {
            &:first-child {
                border-top: 0;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        &__see-more {
            display: none;
        }
    }
}
