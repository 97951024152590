.account-information {
    &__heading {
        margin-bottom: 1rem;
    }

    &__item {
        margin-bottom: 1rem;
    }

    &__item-label,
    &__preference-label {
        @extend %label;
        margin-bottom: 0.35rem;
    }

    &__item-field {
        display: flex;
        column-gap: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $color-border;
    }

    &__error {
        @extend %alert;
        @extend %alert-danger;
        display: inline-block;
        margin-top: 0.5rem;
    }

    &__item-value {
        @extend %text-accent;
        @extend %text-large;
        flex: 1;
        width: 100%;
        line-height: 25px;
        word-break: break-word;

        .loading & {
            &,
            &::placeholder {
                color: $color-text-muted;
            }
        }
    }

    &__item-input {
        padding: 0;
        border: 0;
        background: none;

        &:not(:disabled) {
            padding: 0 5px;
            background-color: $color-background-accent;
            color: $color-text;
            font-size: 16px;
        }
    }

    &__item-links {
        @include center-flex(y);
        column-gap: 0.5rem;
    }

    &__item-link {
        @include button-remove;
        display: block;
        padding: 0.25rem;

        &.edit {
            @include icon-image($asset-icon-edit, $width: 16px);
            font-size: 0;

            &.disabled {
                filter: grayscale(1);
                opacity: 0.5;
            }
        }

        &.save,
        &.cancel {
            @extend %link-accent;
            line-height: 16px;
        }
    }

    &__preferences {
        display: flex;
        flex-wrap: wrap;
        column-gap: 4rem;
        row-gap: 1rem;
        margin-top: 2rem;
    }

    &__preference {
        width: 150px;
    }

    &__preference-field {
        @include center-flex(y);
        column-gap: 0.5rem;
    }

    &__preference-toggle {
        @include button-remove;
        font-size: 28px;
        line-height: 1;

        &.off {
            @include icon-content($icon-toggle-off);
            color: $color-text;
        }

        &.on {
            @include icon-content($icon-toggle-on);
            color: $color-heading-accent;
        }

        &.disabled {
            color: $color-text-muted;
        }
    }

    &__preference-toggle-label {
        color: $color-text-accent;

        &.disabled {
            color: $color-text-muted;
        }
    }

    &__modal .msc-modal__content {
        width: 500px !important;
        max-width: 100%;
    }
}
