footer,
.landing-footer {
    background-color: $color-background-accent;

    .footer {
        @extend %width-page;
        padding-top: 2rem;
        padding-bottom: 2rem;

        > .container-with-header__slots {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
                justify-content: space-between;
                gap: 3rem;
            }
        }
    }

    .contact {
        > .container-with-header__slots {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            @include media-breakpoint-up(md) {
                flex-direction: row;
                gap: 3rem;
            }
        }
    }

    .hidden-footer {
        display: none !important;
    }
}

.footer {
    @extend %text-small;

    .legal {
        .list-of-links__links {
            @include media-breakpoint-down(xs) {
                display: flex;
                flex-direction: column;
            }
        }

        .list-of-links__link {
            @extend %link-secondary;
            margin-right: 2rem;

            &:last-child {
                margin-right: 0;
            }

            @include media-breakpoint-down(xs) {
                margin: 0;
                line-height: 2.5;
            }
        }
    }
}

.landing-footer .list-of-links__link {
    text-align: center;
}
