.aad {
    &-image {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            position: fixed;
            width: 50%;
            height: 100vh;
        }

        .ms-content-block__image {
            width: 100%;
            height: 100%;

            img {
                @include image-cover;
            }
        }
    }

    &-contents {
        min-width: 350px;
        margin: auto;
        padding: $padding-container-aad-contents;

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        > .container-with-header__slots {
            row-gap: 2rem;
            max-width: 500px;
            margin: auto;

            @include media-breakpoint-up(md) {
                max-width: 600px;
            }
        }

        &__footer.ctastyle__default .ms-content-block__cta {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            margin-top: -1rem;

            > * {
                margin: 0;

                &:first-child {
                    @extend %button-secondary;
                }
            }
        }
    }

    &-image + .aad-contents {
        @include media-breakpoint-up(md) {
            margin-right: unset;
        }

        > .container-with-header__slots {
            @include media-breakpoint-up(md) {
                margin-left: unset;
            }
        }
    }

    label {
        @extend %label;
    }

    input {
        @extend %input;
    }

    select {
        @extend %select;
    }

    [aria-hidden='true'] {
        display: none;
    }

    [aria-hidden='false'] {
        display: block !important;
    }

    .error {
        @extend %alert;
        @extend %alert-danger;
        margin-bottom: 0.5rem;
    }

    *[class*='success-text'],
    *[class*='success'] {
        @extend %alert;
        @extend %alert-success;
        margin-bottom: 0.5rem;
    }

    .entry-item {
        margin-bottom: 1rem;
    }

    .msc-btn {
        @extend %button-primary;
        width: 100%;
        margin-top: 1rem;

        + .msc-btn {
            @extend %button-secondary;
        }
    }
}

.ms-sign-in {
    &__social-accounts,
    &__b2b-social-accounts,
    &__sign-up-section {
        display: none;
    }

    &__account-item-password {
        margin-bottom: 0.25rem !important;
    }

    &__forget-password {
        @extend %link-secondary;
        @extend %text-small;
        @include link-text-decoration(underline);
        display: inline-block;
        margin-bottom: 1rem;
    }
}

.ms-sign-in,
.ms-sign-up,
.aad-contents__footer,
.account-lookup__body {
    @include media-breakpoint-up(md) {
        max-width: 345px;
    }
}

.ms-sign-up {
    &__email-verification-buttons {
        margin-bottom: 1rem;

        > *:first-child {
            margin-top: 0;
        }
    }
}

/* START - B2C page local testing */
// .modal-open {
//     overflow: auto !important;
//     position: static !important;
// }

// .msc-modal,
// .msc-modal__backdrop {
//     display: none !important;
// }
/* END - B2C page local testing */
