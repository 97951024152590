//==============================================================================
// BREAKPOINTS
//==============================================================================
$breakpoint-xxxl: 1550px;
$breakpoint-xxl: 1400px;
$breakpoint-xl: 1200px;
$breakpoint-lg: 992px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;
$breakpoint-xs: 450px;
$breakpoint-xxs: 400px;
$breakpoint-xxxs: 350px;
$breakpoint-default: xl;

//==============================================================================
// LAYOUT
//==============================================================================

// Base
$width-page: 100%;
$width-page__mobile: 100%;

$min-width-page: 375px;
$max-width-page: 1500px;

$margin-page: 0 auto;
$margin-page__mobile: $margin-page;

$padding-page: 0 2rem;
$padding-page__mobile: 0 1.5rem;

//==============================================================================
// ELEMENTS
//==============================================================================

// Text
$margin-top-text: 0.5rem;
$margin-bottom-text: 1rem;

// Heading
$margin-top-heading: 0.5rem;
$margin-bottom-heading: 1rem;
$margin-bottom-subheading: 0.5rem;

// List
$padding-left-list: 1rem;
$margin-top-list: 0.5rem;
$margin-bottom-list-item: 1rem;

// Button
$padding-button: 0.75rem 3.5rem;
$margin-top-button: 2rem;
$margin-y-button: 1rem;
$margin-x-button: 1rem;
$border-width-button: 1px;
$border-style-button: solid;
$border-radius-button: 25px;

$padding-button-primary: $padding-button;
$border-width-button-primary: $border-width-button;
$border-style-button-primary: $border-style-button;
$border-radius-button-primary: $border-radius-button;

$padding-button-secondary: $padding-button-primary;
$border-width-button-secondary: $border-width-button-primary;
$border-style-button-secondary: $border-style-button-primary;
$border-radius-button-secondary: $border-radius-button-primary;

$padding-button-accent: $padding-button-primary;
$border-width-button-accent: $border-width-button-primary;
$border-style-button-accent: $border-style-button-primary;
$border-radius-button-accent: $border-radius-button-primary;

$padding-button-featured: $padding-button-primary;
$border-width-button-featured: $border-width-button-primary;
$border-style-button-featured: $border-style-button-primary;
$border-radius-button-featured: $border-radius-button-primary;

// Input
$padding-input: 0.5rem 1rem;
$padding-select: 0.5rem 0.75rem;
$border-width-input: 1px;
$border-style-input: solid;
$border-radius-input: 5px;

// Label
$margin-bottom-label: 0.15rem;
