:not(.ms-header__modal) {
    > .msc-modal {
        @extend %modal;

        .msc-modal__dialog {
            @extend %modal-dialog;
        }

        .msc-modal__content {
            @extend %modal-content;
        }

        .msc-modal__header {
            @extend %modal-header;
        }

        .msc-modal__title {
            @extend %modal-heading;
        }

        .msc-modal__body {
            @extend %modal-body;
        }

        .msc-modal__footer {
            @extend %modal-footer;
        }

        .msc-modal__close-button {
            @extend %modal-close;
        }

        .msc-modal__back-button {
            @extend %modal-back;
        }
    }

    > .msc-modal__backdrop {
        @extend %modal-backdrop;
    }
}
