%button {
    @include button;

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-disabled__border,
            $color-button-disabled__background,
            $color-button-disabled__text,
            $color-button-disabled__border,
            $color-button-disabled__background,
            $color-button-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-inverse__border,
            $color-button-inverse__background,
            $color-button-inverse__text,
            $color-button-inverse__border,
            $color-button-inverse__background,
            $color-button-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-inverse-disabled__border,
                $color-button-inverse-disabled__background,
                $color-button-inverse-disabled__text,
                $color-button-inverse-disabled__border,
                $color-button-inverse-disabled__background,
                $color-button-inverse-disabled__text
            );
        }
    }
}

%button-primary {
    @include button(
        $padding-button-primary,
        $border-width-button-primary,
        $border-style-button-primary,
        $border-radius-button-primary,
        $color-button-primary__border,
        $color-button-primary__background,
        $color-button-primary__text,
        $color-button-primary__border--hover,
        $color-button-primary__background--hover,
        $color-button-primary__text--hover,
        $font-family-button-primary,
        $font-size-button-primary,
        $font-size-button-primary__mobile,
        $font-weight-button-primary,
        $font-style-button-primary,
        $letter-spacing-button-primary,
        $line-height-button-primary,
        $line-height-button-primary__mobile,
        $text-transform-button-primary,
        $text-decoration-button-primary,
        $text-decoration-button-primary--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-primary-disabled__border,
            $color-button-primary-disabled__background,
            $color-button-primary-disabled__text,
            $color-button-primary-disabled__border,
            $color-button-primary-disabled__background,
            $color-button-primary-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-primary-inverse__border,
            $color-button-primary-inverse__background,
            $color-button-primary-inverse__text,
            $color-button-primary-inverse__border,
            $color-button-primary-inverse__background,
            $color-button-primary-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-primary-inverse-disabled__border,
                $color-button-primary-inverse-disabled__background,
                $color-button-primary-inverse-disabled__text,
                $color-button-primary-inverse-disabled__border,
                $color-button-primary-inverse-disabled__background,
                $color-button-primary-inverse-disabled__text
            );
        }
    }
}

%button-secondary {
    @include button(
        $padding-button-secondary,
        $border-width-button-secondary,
        $border-style-button-secondary,
        $border-radius-button-secondary,
        $color-button-secondary__border,
        $color-button-secondary__background,
        $color-button-secondary__text,
        $color-button-secondary__border--hover,
        $color-button-secondary__background--hover,
        $color-button-secondary__text--hover,
        $font-family-button-secondary,
        $font-size-button-secondary,
        $font-size-button-secondary__mobile,
        $font-weight-button-secondary,
        $font-style-button-secondary,
        $letter-spacing-button-secondary,
        $line-height-button-secondary,
        $line-height-button-secondary__mobile,
        $text-transform-button-secondary,
        $text-decoration-button-secondary,
        $text-decoration-button-secondary--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-secondary-disabled__border,
            $color-button-secondary-disabled__background,
            $color-button-secondary-disabled__text,
            $color-button-secondary-disabled__border,
            $color-button-secondary-disabled__background,
            $color-button-secondary-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-secondary-inverse__border,
            $color-button-secondary-inverse__background,
            $color-button-secondary-inverse__text,
            $color-button-secondary-inverse__border,
            $color-button-secondary-inverse__background,
            $color-button-secondary-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-secondary-inverse-disabled__border,
                $color-button-secondary-inverse-disabled__background,
                $color-button-secondary-inverse-disabled__text,
                $color-button-secondary-inverse-disabled__border,
                $color-button-secondary-inverse-disabled__background,
                $color-button-secondary-inverse-disabled__text
            );
        }
    }
}

%button-accent {
    @include button(
        $padding-button-accent,
        $border-width-button-accent,
        $border-style-button-accent,
        $border-radius-button-accent,
        $color-button-accent__border,
        $color-button-accent__background,
        $color-button-accent__text,
        $color-button-accent__border--hover,
        $color-button-accent__background--hover,
        $color-button-accent__text--hover,
        $font-family-button-accent,
        $font-size-button-accent,
        $font-size-button-accent__mobile,
        $font-weight-button-accent,
        $font-style-button-accent,
        $letter-spacing-button-accent,
        $line-height-button-accent,
        $line-height-button-accent__mobile,
        $text-transform-button-accent,
        $text-decoration-button-accent,
        $text-decoration-button-accent--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-accent-disabled__border,
            $color-button-accent-disabled__background,
            $color-button-accent-disabled__text,
            $color-button-accent-disabled__border,
            $color-button-accent-disabled__background,
            $color-button-accent-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-accent-inverse__border,
            $color-button-accent-inverse__background,
            $color-button-accent-inverse__text,
            $color-button-accent-inverse__border,
            $color-button-accent-inverse__background,
            $color-button-accent-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-accent-inverse-disabled__border,
                $color-button-accent-inverse-disabled__background,
                $color-button-accent-inverse-disabled__text,
                $color-button-accent-inverse-disabled__border,
                $color-button-accent-inverse-disabled__background,
                $color-button-accent-inverse-disabled__text
            );
        }
    }
}

%button-featured {
    @include button(
        $padding-button-featured,
        $border-width-button-featured,
        $border-style-button-featured,
        $border-radius-button-featured,
        $color-button-featured__border,
        $color-button-featured__background,
        $color-button-featured__text,
        $color-button-featured__border--hover,
        $color-button-featured__background--hover,
        $color-button-featured__text--hover,
        $font-family-button-featured,
        $font-size-button-featured,
        $font-size-button-featured__mobile,
        $font-weight-button-featured,
        $font-style-button-featured,
        $letter-spacing-button-featured,
        $line-height-button-featured,
        $line-height-button-featured__mobile,
        $text-transform-button-featured,
        $text-decoration-button-featured,
        $text-decoration-button-featured--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-featured-disabled__border,
            $color-button-featured-disabled__background,
            $color-button-featured-disabled__text,
            $color-button-featured-disabled__border,
            $color-button-featured-disabled__background,
            $color-button-featured-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-featured-inverse__border,
            $color-button-featured-inverse__background,
            $color-button-featured-inverse__text,
            $color-button-featured-inverse__border,
            $color-button-featured-inverse__background,
            $color-button-featured-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-featured-inverse-disabled__border,
                $color-button-featured-inverse-disabled__background,
                $color-button-featured-inverse-disabled__text,
                $color-button-featured-inverse-disabled__border,
                $color-button-featured-inverse-disabled__background,
                $color-button-featured-inverse-disabled__text
            );
        }
    }
}

.button {
    @extend %button;
}

.button-inverse {
    @extend %button-inverse;
}

.button-primary {
    @extend %button-primary;
}

.button-primary-inverse {
    @extend %button-primary-inverse;
}

.button-secondary {
    @extend %button-secondary;
}

.button-secondary-inverse {
    @extend %button-secondary-inverse;
}

.button-accent {
    @extend %button-accent;
}

.button-accent-inverse {
    @extend %button-accent-inverse;
}

.button-featured {
    @extend %button-featured;
}

.button-featured-inverse {
    @extend %button-featured-inverse;
}
