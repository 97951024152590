.ms-map {
    @include media-breakpoint-down(sm) {
        display: none;
        height: 450px;

        &.show {
            display: block;
        }
    }

    .ms-store-select + &,
    .ms-store-locate + & {
        @include media-breakpoint-down(sm) {
            margin-top: -3rem;
        }
    }
}

.ms-store-select,
.ms-store-locate,
.ms-map {
    @include media-breakpoint-up(md) {
        height: 600px;
    }
}
