.container-with-header {
    > .container-with-header__header {
        &:empty {
            display: none !important;
        }

        > .container-with-header__text {
            margin-bottom: $margin-bottom-text-container-with-header;
        }

        > .container-with-header__title {
            margin-bottom: $margin-bottom-heading-container-with-header;
        }

        &:last-child {
            > .container-with-header__text,
            > .container-with-header__title:last-child {
                margin-bottom: 0;
            }
        }
    }

    > .container-with-header__slots {
        display: flex;
        flex-direction: column;
        column-gap: 2rem;
        row-gap: 1rem;
    }

    > .container-with-header__links {
        margin-top: 1rem;

        > * {
            @include media-breakpoint-down(xxs) {
                @include button-width-full;
                margin-bottom: $margin-bottom-button-content-block__mobile-down;
            }

            @include media-breakpoint-up(xs) {
                margin-right: $margin-right-button-content-block__tablet-up;
            }

            &:last-child {
                @include media-breakpoint-down(xxs) {
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(xs) {
                    margin-right: 0;
                }
            }
        }
    }

    &.layout {
        &__columns > .container-with-header__slots {
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            > * {
                @include media-breakpoint-up(md) {
                    flex: 1;
                }
            }
        }
    }

    &.fill {
        &__section {
            @extend %width-page;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }

        &__container {
            padding: 1rem 2rem;
        }

        &__full {
            padding: 0 !important;
            overflow: hidden;
        }
    }

    &.width {
        &__full {
            width: 100%;
        }

        &__large {
            @include media-breakpoint-up(md) {
                width: 95%;
            }

            @include media-breakpoint-up(lg) {
                width: 85%;
            }

            @include media-breakpoint-up(xl) {
                width: 75%;
            }
        }

        &__medium {
            @include media-breakpoint-up(md) {
                width: 90%;
            }

            @include media-breakpoint-up(lg) {
                width: 75%;
            }

            @include media-breakpoint-up(xl) {
                width: 60%;
            }
        }

        &__small {
            @include media-breakpoint-up(md) {
                width: 75%;
            }

            @include media-breakpoint-up(lg) {
                width: 65%;
            }

            @include media-breakpoint-up(xl) {
                width: 50%;
            }
        }
    }

    &.columnwidths {
        $width-column: calc((((100% - 2rem) / 3) * 2) + 1rem);

        &__first > .container-with-header__slots > *:first-child {
            @include media-breakpoint-up(md) {
                @include flex-width($width-column);
            }
        }

        &__last > .container-with-header__slots > *:last-child {
            @include media-breakpoint-up(md) {
                @include flex-width($width-column);
            }
        }
    }

    &.background {
        &__white {
            background-color: $color-background;
        }

        &__gray {
            background-color: $color-background-accent;
        }

        &__blue {
            background-color: $color-background-featured;
        }

        &__darkblue {
            background: linear-gradient($color-background-accent-inverse, $color-background-inverse);
        }
    }

    &.horizontalalignment {
        &__center {
            .container-with-header__header,
            .container-with-header__links {
                text-align: center;
            }
        }

        &__right {
            .container-with-header__header,
            .container-with-header__links {
                text-align: right;
            }
        }
    }

    &.headingstyle {
        &__inverse > .container-with-header__header > .container-with-header__title {
            @extend %heading-inverse;
        }

        &__primary > .container-with-header__header > .container-with-header__title {
            @extend %heading-primary;
        }

        &__secondary > .container-with-header__header > .container-with-header__title {
            @extend %heading-secondary;
        }

        &__accent > .container-with-header__header > .container-with-header__title {
            @extend %heading-accent;
        }

        &__featured > .container-with-header__header > .container-with-header__title {
            @extend %heading-featured;
        }
    }

    &.textstyle {
        &__inverse > .container-with-header__header > .container-with-header__text {
            @extend %text-inverse;
        }

        &__accent > .container-with-header__header > .container-with-header__text {
            @extend %text-accent;
        }

        &__featured > .container-with-header__header > .container-with-header__text {
            @extend %text-featured;
        }
    }

    &.ctastyle {
        &__default > .container-with-header__links > * {
            &:first-child {
                @extend %button-primary;
            }

            &:not(:first-child) {
                @extend %button-secondary;
            }
        }

        &__accent > .container-with-header__links > * {
            @extend %button-accent;
        }

        &__featured > .container-with-header__links > * {
            @extend %button-featured;
        }
    }
}
