.account-loyalty-points {
    @extend %text;
    @include center-flex;
    column-gap: 1.5rem;
    row-gap: 1rem;

    &__additional {
        max-width: 160px;
    }

    &__tier {
        @include center-flex;
        flex-direction: column;
        position: relative;
        width: 170px;
        height: 170px;
        padding: 2rem;
        border-radius: 50%;
        text-align: center;

        > * {
            z-index: 2;

            &.account-loyalty-points__tier-progress {
                z-index: 1;
            }
        }

        &-progress {
            position: absolute;

            &,
            &::before,
            &::after {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            &::before,
            &::after {
                content: '';
                display: block;
            }

            &::before {
                position: absolute;
                background: conic-gradient(
                    $color-heading-featured var(--tier-progress),
                    transparent var(--tier-progress)
                );

                $progress-mask: radial-gradient(70px, transparent 98%, $color-black);
                -webkit-mask: $progress-mask;
                mask: $progress-mask;
            }

            &::after {
                border: 10px solid transparentize($color-heading-muted, 0.5);
            }

            &.max {
                &::after {
                    border: 10px solid $color-border-accent;
                }
            }
        }

        &-level {
            font-weight: $font-weight-bold;

            &-label {
                margin-right: 0.25rem;
            }
        }

        &-points {
            @extend %heading-featured;
            @extend %text-large;
            font-weight: $font-weight-bold;

            &-current {
                font-size: 30px;
            }

            &-needed {
                &:empty {
                    display: none;
                }

                &::before {
                    content: '/';
                }
            }
        }

        &-description {
            @extend %text-small;
        }
    }

    &__points {
        &-redeem {
            @include icon-content($icon-star);
            @include center-flex(y);
            @extend %heading-featured;
            column-gap: 0.5rem;
            font-size: 22px;
            font-weight: $font-weight-bold;

            &::before {
                font-size: 16px;
            }
        }

        &-expire {
            @extend %text-small;
            margin-top: 0.5rem;
            font-size: 13px;

            &-points {
                margin-right: 0.3rem;
                color: $color-heading-featured;
            }
        }
    }

    &__content {
        margin-top: 1rem;
    }

    .list-of-links__link {
        @extend %text-small;
        @include link-text-decoration(underline);
        display: block;

        &[aria-label='Points History'] {
            font-weight: $font-weight-bold;
        }
    }

    .background__darkblue & {
        @extend %text-inverse;

        .list-of-links__link {
            @extend %link-inverse;
        }
    }
}
