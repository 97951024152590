// Content Block Heading
$margin-bottom-heading-content-block: $margin-bottom-heading;

// Content Block Button
$margin-top-button-content-block: $margin-top-button;
$margin-bottom-button-content-block__mobile-down: $margin-y-button;
$margin-right-button-content-block__tablet-up: $margin-x-button;

// Content Block Link
$text-decoration-link-content-block: underline;

%link-content-block {
    @extend %link;
    @include link-text-decoration($text-decoration-link-content-block);
}

// Content Block Icon
$margin-bottom-icon-content-block: 1rem;

// Content Block Gradient
$background-container-content-block-gradient-top: linear-gradient(
    0deg,
    transparentize($color-black, 1) 50%,
    transparentize($color-black, 0.6) 70%,
    transparentize($color-black, 0.1) 100%
);

$background-container-content-block-gradient-bottom: linear-gradient(
    180deg,
    transparentize($color-black, 1) 50%,
    transparentize($color-black, 0.5) 70%,
    transparentize($color-black, 0.1) 100%
);

$background-container-content-block-gradient-left: linear-gradient(
    270deg,
    transparentize($color-black, 1) 50%,
    transparentize($color-black, 0.5) 70%,
    transparentize($color-black, 0.1) 100%
);

$background-container-content-block-gradient-right: linear-gradient(
    90deg,
    transparentize($color-black, 1) 50%,
    transparentize($color-black, 0.5) 70%,
    transparentize($color-black, 0.1) 100%
);
