.ms-address-list,
.msc-address-list {
    &__button-add,
    &__button-edit {
        @extend %button-primary;
        margin-top: 1rem;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__button-remove,
    &__button-primary {
        @include button-remove;
        @extend %text-small;
        @extend %link;

        @include plain-hover-all {
            text-decoration: underline;
        }
    }

    &__button-primary {
        margin-right: 0.75rem;
    }

    &__button-remove {
        margin-left: 0.75rem;
    }

    [class$='-heading'] {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    [class$='-list'] {
        margin-bottom: 1.25rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
