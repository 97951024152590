//==============================================================================
// FONT IMPORTS
//==============================================================================
$font-family-path: '../../../webfonts';
$font-family-path-worksans: 'WorkSans';

// Font Family Names
$font-family-name-worksans: 'Work Sans';

// Font Weight Values
$font-weight-value-worksans-regular: 400;
$font-weight-value-worksans-medium: 500;
$font-weight-value-worksans-semibold: 600;
$font-weight-value-worksans-bold: 700;

//==============================================================================
// FONT FACES
//==============================================================================
@font-face {
    font-family: $font-family-name-worksans;
    font-weight: $font-weight-value-worksans-regular;
    font-style: normal;
    src: url('#{$font-family-path}/#{$font-family-path-worksans}/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: $font-family-name-worksans;
    font-weight: $font-weight-value-worksans-regular;
    font-style: italic;
    src: url('#{$font-family-path}/#{$font-family-path-worksans}/WorkSans-Italic.ttf') format('truetype');
}

@font-face {
    font-family: $font-family-name-worksans;
    font-weight: $font-weight-value-worksans-medium;
    font-style: normal;
    src: url('#{$font-family-path}/#{$font-family-path-worksans}/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: $font-family-name-worksans;
    font-weight: $font-weight-value-worksans-medium;
    font-style: italic;
    src: url('#{$font-family-path}/#{$font-family-path-worksans}/WorkSans-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: $font-family-name-worksans;
    font-weight: $font-weight-value-worksans-semibold;
    font-style: normal;
    src: url('#{$font-family-path}/#{$font-family-path-worksans}/WorkSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: $font-family-name-worksans;
    font-weight: $font-weight-value-worksans-semibold;
    font-style: italic;
    src: url('#{$font-family-path}/#{$font-family-path-worksans}/WorkSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: $font-family-name-worksans;
    font-weight: $font-weight-value-worksans-bold;
    font-style: normal;
    src: url('#{$font-family-path}/#{$font-family-path-worksans}/WorkSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: $font-family-name-worksans;
    font-weight: $font-weight-value-worksans-bold;
    font-style: italic;
    src: url('#{$font-family-path}/#{$font-family-path-worksans}/WorkSans-BoldItalic.ttf') format('truetype');
}

//==============================================================================
// BASE FONTS
//==============================================================================
$font-family-system: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', $font-family-name-worksans, 'Helvetica', 'Arial', sans-serif;
$font-family-worksans: $font-family-name-worksans, 'Helvetica', 'Arial', sans-serif;
