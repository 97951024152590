$margin-y-container-content: 5rem;
$margin-y-container-content__mobile: 3rem;

%width-container-content-max {
    @include width-page-without-padding__breakpoint(xxl);
}

%width-container-content-full {
    @extend %width-page-full;
}

%width-container-content-full__mobile-only {
    @extend %width-page-full__mobile-only;
}

%margin-y-container-content {
    margin-top: $margin-y-container-content__mobile;
    margin-bottom: $margin-y-container-content__mobile;

    @include media-breakpoint-up(xl) {
        margin-top: $margin-y-container-content;
        margin-bottom: $margin-y-container-content;
    }
}

%margin-bottom-container-content {
    margin-bottom: $margin-y-container-content__mobile;

    @include media-breakpoint-up(xl) {
        margin-bottom: $margin-y-container-content;
    }
}
