.store-list {
    &__heading {
        @include icon-image($asset-icon-location);
        @include center-flex(y);
        column-gap: 0.25rem;
    }

    &__locations {
        margin-top: 1rem;
    }

    &__location {
        &-name {
            margin-right: 0.5rem;
            font-weight: $font-weight-bold;
        }

        &-distance {
            display: none;
        }
    }
}
