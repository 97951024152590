// Modal
$z-index-container-modal: 1050;

%modal {
    @extend .modal;
    z-index: $z-index-container-modal;
}

// Modal Dialog
$max-width-container-modal-dialog: 915px;
$min-width-container-modal-dialog: 220px;
$max-height-container-modal-dialog: 615px;
$color-background-modal-dialog: transparent;

%modal-dialog {
    @extend .modal-dialog;
    max-width: $max-width-container-modal-dialog;
    min-width: $min-width-container-modal-dialog;
    max-height: $max-height-container-modal-dialog;
    background-color: $color-background-modal-dialog;

    @include media-breakpoint-up(lg) {
        @include center-absolute(both);
        transform: translate(-50%, -50%) !important;
    }
}

// Modal Content
$border-radius-container-modal-content: 10px;
$border-width-container-modal-content: 0;
$color-border-modal-content: transparent;

%modal-content {
    @extend .modal-content;
    @include border($color-border-modal-content, $border-width-container-modal-content);
    border-radius: $border-radius-container-modal-content;
    box-shadow: 0 0 12px transparentize($color-black, 0.75);
    outline: 0;
    background-clip: border-box;
}

// Modal Header
$padding-container-modal-header: 1.75rem 2rem;
$padding-container-modal-header__mobile: 1.5rem;
$margin-bottom-container-modal-header: -1.5rem;
$z-index-container-modal-header: 5;

%modal-header {
    @extend .modal-header;
    z-index: $z-index-container-modal-header;
    padding: $padding-container-modal-header__mobile;
    margin-bottom: $margin-bottom-container-modal-header;
    border-bottom: 0;
    border-radius: 0;

    @include media-breakpoint-up(lg) {
        padding: $padding-container-modal-header;
    }
}

// Modal Heading
$margin-top-heading-modal: -2px;
$margin-bottom-heading-modal: 0;

%modal-heading {
    margin-top: $margin-top-heading-modal;
    margin-bottom: $margin-bottom-heading-modal;
}

// Modal Body
$padding-container-modal-body: 2rem;
$padding-container-modal-body__mobile: 2rem 1.5rem;

%modal-body {
    @extend .modal-body;
    padding: $padding-container-modal-body__mobile;

    @include media-breakpoint-up(lg) {
        padding: $padding-container-modal-body;
    }
}

// Modal Footer
$z-index-container-modal-footer: 1;
$padding-container-modal-footer: 1.75rem 2rem;
$padding-container-modal-footer__mobile: 1.5rem;
$margin-top-container-modal-footer: -1.5rem;

%modal-footer {
    z-index: $z-index-container-modal-footer;
    padding: $padding-container-modal-footer__mobile;
    margin-top: $margin-top-container-modal-footer;

    @include media-breakpoint-up(lg) {
        padding: $padding-container-modal-footer;
    }

    &:empty {
        display: none;
    }
}

// Modal Back Icon
$icon-modal-back: $icon-arrow-left;
$margin-right-icon-modal-back: 1rem;
$font-size-icon-modal-back: 20px;

%modal-back {
    @include button-remove;
    @include icon-content($icon-modal-back);
    margin-right: $margin-right-icon-modal-back;
    font-size: $font-size-icon-modal-back;
}

// Modal Close Icon
$icon-modal-close: $icon-times;
$font-size-icon-modal-close: 20px;

%modal-close {
    @extend .close;
    @include icon-content($icon-modal-close);
    font-size: $font-size-icon-modal-close;

    @include plain-hover-all {
        opacity: 1;
    }
}

// Modal Backdrop
%modal-backdrop {
    @extend .modal-backdrop;
}
